import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { clearError, clearSuccessMessage } from "../../reducers/alert/alert";
import { RootState } from "../../reducers/store";

const GenericAlert: React.FC = () => {
  const dispatch = useDispatch();
  const errorMessage = useSelector(
    (state: RootState) => state.error.errorMessage
  );
  const successMessage = useSelector(
    (state: RootState) => state.error.successMessage
  ); // Get success message from state

  const handleClose = () => {
    dispatch(clearError());
    dispatch(clearSuccessMessage()); // Clear success message on close
  };

  return (
    <Snackbar
      open={!!errorMessage || !!successMessage} // Check if either error or success message is present
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity={errorMessage ? "error" : "success"} // Determine severity based on presence of error or success message
        onClose={handleClose}
      >
        {errorMessage || successMessage}{" "}
        {/* Show either error or success message */}
      </MuiAlert>
    </Snackbar>
  );
};

export default GenericAlert;
