import { PermissionsEnum } from "@interfaces/user";
import { RootState } from "@reducers/store";
import { checkPermissions } from "@utils/checkPermissions";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import useNavigate

interface ProtectedComponentProps {
  requiredPermissions: PermissionsEnum[]; // List of required permissions
  children: React.ReactNode; // The component(s) to render if permissions are met
  isRoute?: boolean; // Indicates whether to redirect or render null
}

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({
  requiredPermissions,
  children,
  isRoute = false,
}) => {
  // Get the user's permissions from Redux store
  const userPermissions = useSelector(
    (state: RootState) => state.auth.user?.role?.permissions
  );

  const navigate = useNavigate(); // Initialize useNavigate

  // Memoize the permission check result
  const hasPermission = useMemo(() => {
    return checkPermissions(requiredPermissions, userPermissions);
  }, [requiredPermissions, userPermissions]);

  useEffect(() => {
    // Navigate to '/' if permissions are not met and isRoute is true
    if (!hasPermission && isRoute) {
      navigate("/");
    }
  }, [hasPermission, navigate, isRoute]);

  // If the user has all the required permissions, render the children
  return hasPermission ? (
    <>{children}</>
  ) : isRoute ? null : (
    <div>Access Denied</div>
  );
};

export default ProtectedComponent;
