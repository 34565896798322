import apiCall, { ApiResponse } from "@api/http";
import {
  ICreateRoleDTO,
  IGetAllRollesDTO,
  IUpdateRoleDTO,
} from "@api/interfaces/api-calls";
import { IRole } from "@interfaces/role";

const endPoint = `internal/role`;

export const createRoleService = async (
  requestData: ICreateRoleDTO
): Promise<ApiResponse<IRole>> => {
  return await apiCall<IRole>("POST", endPoint, requestData);
};

export const updateRoleService = async (
  _id: string,
  requestData: IUpdateRoleDTO
): Promise<ApiResponse<IRole>> => {
  return await apiCall<IRole>("PUT", endPoint + `/${_id}`, requestData);
};

export const listRolesService = async (
  requestData: IGetAllRollesDTO
): Promise<ApiResponse<IRole[]>> => {
  return await apiCall("GET", endPoint, undefined, requestData);
};

export const deleteRoleService = async (
  _id: string
): Promise<ApiResponse<IRole>> => {
  return await apiCall<IRole>("DELETE", endPoint + `/${_id}`);
};

export const getOneRoleService = async (
  _id: string
): Promise<ApiResponse<IRole>> => {
  return await apiCall<IRole>("GET", endPoint + `/${_id}`);
};

export const assignRoleToUserService = async ({
  userId,
  roleId,
}: {
  userId: string;
  roleId: string;
}): Promise<ApiResponse<null>> => {
  return await apiCall<null>("POST", endPoint + `/${roleId}/users/${userId}`);
};

export const deleteRoleFromUserService = async ({
  userId,
  roleId,
}: {
  userId: string;
  roleId: string;
}): Promise<ApiResponse<null>> => {
  return await apiCall<null>("DELETE", endPoint + `/${roleId}/users/${userId}`);
};
