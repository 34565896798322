// ----------------------------------------------------------------------

export const HEADER = {
    H_MOBILE: 64,
    H_DESKTOP: 80,
    H_DESKTOP_OFFSET: 80 - 16,
  };
  
  export const NAV = {
    WIDTH: 280,
  };
  