import PropTypes from "prop-types";
import { useMemo } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";

import { CacheProvider } from "@emotion/react";
import { Box } from "@mui/material";
import { prefixer } from "stylis";
import stylisRTLPlugin from "stylis-plugin-rtl";
import { customShadows } from "./custom-shadows";
import { useLanguage } from "./language";
import { overrides } from "./overrides";
import { palette } from "./palette";
import { shadows } from "./shadows";
import { typography } from "./typography";

import createCache from "@emotion/cache";
// ----------------------------------------------------------------------
// eslint-disable-next-line
const rtlCache = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, stylisRTLPlugin],
});

const ltrCache = createCache({
  key: "mui",
});

export default function ThemeProvider({ children }: { children: any }) {
  const { direction: currentAppDirection } = useLanguage();

  const memoizedValue: any = useMemo(
    () => ({
      direction: currentAppDirection,
      palette: palette(),
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
      shape: { borderRadius: 8 },
    }),
    [currentAppDirection]
  );

  const theme = createTheme(memoizedValue);

  (theme as any).components = overrides(theme);

  return (
    <CacheProvider value={currentAppDirection === "rtl" ? rtlCache : ltrCache}>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <Box width={1} height={1} dir={currentAppDirection}>
          {children}
        </Box>
      </MUIThemeProvider>
    </CacheProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
