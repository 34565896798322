import { Grid, CircularProgress, Box } from "@mui/material";

const LoadingComponent = () => {
  return (
    <Box
      width={1}
      display='flex'
      sx={{ justifyContent: "center", alignItems: "center", p: 3 }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingComponent;
