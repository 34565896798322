// errorSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ErrorState {
  errorMessage: string | null;
  successMessage: string | null; // Add success message
}

const initialState: ErrorState = {
  errorMessage: null,
  successMessage: null, // Initialize success message to null
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setError(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setSuccessMessage(state, action: PayloadAction<string>) {
      // Add setSuccessMessage reducer
      state.successMessage = action.payload;
    },
    clearError(state) {
      state.errorMessage = null;
    },
    clearSuccessMessage(state) {
      // Add clearSuccessMessage reducer
      state.successMessage = null;
    },
  },
});

export const { setError, setSuccessMessage, clearError, clearSuccessMessage } =
  alertSlice.actions;
export default alertSlice.reducer;
