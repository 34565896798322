import axios, { AxiosResponse } from "axios";

type CallResponse<T> = {
  success: boolean;
  message?: string;
  data?: T;
};
// Define a base URL for your API
const BASE_URL = process.env.REACT_APP_BASE_API_URL;

// Define your static headers
const staticHeaders: any = {
  "Content-Type": "application/json",
};

// Define a generic function for making API calls
const apiCallAxios = async <T>(
  method: "GET" | "POST" | "PUT" | "DELETE",
  endpoint: string,
  data?: any,
  queryParams?: Record<string, any>
) => {
  try {
    const token = localStorage.getItem(`Auth`);

    if (token) {
      staticHeaders["Authorization"] = `Bearer ${token}`;
    }

    staticHeaders["x-3afya-gate"] = "portal";
    const locale = localStorage.getItem("app:locale");

    if (locale === "ar") {
      staticHeaders["accept-language"] = "ar";
    } else {
      staticHeaders["accept-language"] = "en";
    }

    let response: AxiosResponse;
    switch (method) {
      case "GET":
        response = await axios.get<T>(`${BASE_URL}${endpoint}`, {
          params: queryParams,
          headers: staticHeaders,
        });
        break;
      case "POST":
        response = await axios.post<T>(`${BASE_URL}${endpoint}`, data, {
          params: queryParams,
          headers: staticHeaders,
        });
        break;
      case "PUT":
        response = await axios.put<T>(`${BASE_URL}${endpoint}`, data, {
          params: queryParams,
          headers: staticHeaders,
        });
        break;
      case "DELETE":
        response = await axios.delete<T>(`${BASE_URL}${endpoint}`, {
          params: queryParams,
          headers: staticHeaders,
        });
        break;
      default:
        throw new Error("Invalid HTTP method");
    }
    return response.data as CallResponse<any>;
  } catch (error: any) {
    console.error(error);
    return (error.response?.data || {
      success: false,
      message: "Unkown Error",
    }) as CallResponse<any>;
  }
};

export async function uploadFileApi(presignedUrl: string, file: File) {
  try {
    await axios.put(presignedUrl, file, {
      headers: {
        "Content-Type": file.type,
        "Content-Length": file.size,
      },
    });

    return presignedUrl;
  } catch (error) {
    throw error;
  }
}

export default apiCallAxios;
