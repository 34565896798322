import { updateRoleService } from "@api/services/role";
import { IRole } from "@interfaces/role";
import { PermissionsEnum } from "@interfaces/user";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

// Yup validation schema

const SingleRoleForm: React.FC<{
  role: IRole;
  onSuccess: () => void;
}> = ({ role, onSuccess }) => {
  const { t } = useTranslation("roles");
  const { t: tForm } = useTranslation("form");
  const RoleSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(
          tForm("validation.required", {
            path: t("props.arName"),
          })
        ),
      }),
    [t, tForm]
  );

  const formik = useFormik({
    initialValues: {
      name: role.name,
      permissions: role.permissions || {},
    },
    validationSchema: RoleSchema,

    onSubmit: async (values) => {
      const res = await updateRoleService(role._id, {
        name: values.name,
        permissions: values.permissions,
      });

      if (res.success) {
        onSuccess();
      }
    },
  });

  const handleCheckboxChange = (permission: PermissionsEnum) => {
    formik.setFieldValue(
      `permissions.${permission}`,
      formik.values.permissions[permission] ? undefined : permission
    );
  };

  return (
    <Grid
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{ width: 1 }}
      container
      rowSpacing={2}
    >
      <Grid item xs={12}>
        <TextField
          label={t("props.name")}
          variant="outlined"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          fullWidth
        />
      </Grid>

      <Grid xs={12} container item>
        {Object.values(PermissionsEnum).map((permission) => (
          <Grid key={permission} item xs={6}>
            <FormControlLabel
              key={permission}
              control={
                <Checkbox
                  checked={Boolean(formik.values.permissions[permission])}
                  onChange={() => handleCheckboxChange(permission)}
                />
              }
              label={t(`permissions.${permission}`)}
            />
          </Grid>
        ))}
      </Grid>

      <Grid xs={12} item>
        <Button variant="contained" color="primary" type="submit">
          {t("save")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SingleRoleForm;
