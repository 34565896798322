import { PermissionsEnum } from "@interfaces/user";
import Iconify from "../components/iconify";

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: "dashboard",
    path: "/",
    icon: <Iconify icon="material-symbols-light:bar-chart" width={22} />,
    requiredPermissions: [PermissionsEnum.CAN_VIEW_STATS],
  },
  {
    title: "roles",
    path: "/roles",
    icon: <Iconify icon="material-symbols:verified-user-outline" width={22} />,
    requiredPermissions: [PermissionsEnum.CAN_MANAGE_ROLES],
  },
  {
    title: "users",
    path: "/users",
    icon: <Iconify icon="material-symbols:person" width={22} />,
    requiredPermissions: [PermissionsEnum.CAN_MANAGE_USERS],
  },
  {
    title: "tags",
    path: "/tags",
    icon: <Iconify width={22} icon="material-symbols:tag" />,
    requiredPermissions: [PermissionsEnum.CAN_MANAGE_TAGS],
  },
  {
    title: "medical-questions",
    path: "/medical-questions",
    icon: (
      <Iconify icon="material-symbols:medical-services-outline" width={22} />
    ),
    requiredPermissions: [PermissionsEnum.CAN_MANAGE_MEDICAL_QUESTIONS],
  },
  {
    title: "consulting-packages",
    path: "/consulting-packages",
    icon: <Iconify width={22} icon="material-symbols:package" />,
    requiredPermissions: [PermissionsEnum.CAN_MANAGE_PACKAGES],
  },
  {
    title: "consultings",
    path: "/consultings",
    icon: <Iconify icon="material-symbols:media-output" width={22} />,
    requiredPermissions: [PermissionsEnum.CAN_MANAGE_CONSULTING],
  },
  {
    title: "doctors",
    path: "/doctors",
    icon: <Iconify icon="fa6-solid:user-doctor" width={20} />,
    requiredPermissions: [PermissionsEnum.CAN_MANAGE_USERS],
  },
  {
    title: "categories",
    path: "/categories",
    icon: <Iconify width={22} icon="material-symbols-light:category-rounded" />,
    requiredPermissions: [PermissionsEnum.CAN_MANAGE_HOME_VIDEO_CATEGORIES],
  },
  {
    title: "homeBanners",
    path: "/home-banners",
    icon: (
      <Iconify
        width={22}
        icon="material-symbols:planner-banner-ad-pt-outline"
      />
    ),
    requiredPermissions: [PermissionsEnum.CAN_MANAGE_HOME_BANNERS],
  },
  {
    title: "articles",
    path: "/articles",
    icon: <Iconify width={22} icon="material-symbols:article" />,
    requiredPermissions: [PermissionsEnum.CAN_MANAGE_ARTICLES],
  },
  {
    title: "complaints",
    path: "/complaints",
    icon: <Iconify width={22} icon="material-symbols:contact-mail" />,
    requiredPermissions: [PermissionsEnum.CAN_MANAGE_COMPLAINTS],
  },
  {
    title: "notifications",
    path: "/notifications",
    icon: <Iconify width={22} icon="material-symbols:notifications-outline" />,
    requiredPermissions: [PermissionsEnum.CAN_MANAGE_NOTIFICATIONS],
  },
  {
    title: "settings",
    path: "/settings",
    icon: <Iconify width={22} icon="material-symbols:settings" />,
    requiredPermissions: [PermissionsEnum.CAN_MANAGE_SETTINGS],
  },
];

export default navConfig;
