import Box from "@mui/material/Box";

import { SxProps } from "@mui/material";
import { useLanguage } from "@theme/language";
import React from "react";
import { useResponsive } from "../hooks/use-responsive";
import { HEADER, NAV } from "./config-layout";

// ----------------------------------------------------------------------

const SPACING = 8;

export default function Main({
  children,
  sx,
  ...other
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) {
  const lgUp = useResponsive("up", "lg");
  const { direction: currentAppDirection } = useLanguage();
  return (
    <Box
      dir={currentAppDirection}
      component="main"
      sx={{
        overflowY: "scroll",
        overflowX: "hidden",
        flexGrow: 1,
        minHeight: 1,
        display: "flex",
        flexDirection: "column",
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.WIDTH}px)`,
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}
