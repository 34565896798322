// authSlice.ts

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMeService, loginService } from "../../api/services/auth";
import { IUser } from "../../interfaces/user";
import { RootState } from "../store";

export interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  user?: IUser;
}

const initialState: AuthState = {
  isAuthenticated: false,
  loading: true,
};

// Async action to check user authentication status from API
export const checkAuthStatus = createAsyncThunk(
  "auth/checkAuthStatus",
  async (_, thunkAPI) => {
    const token = localStorage.getItem(`Auth`);

    if (!token) throw new Error("Auth Error");
    // Pass a second argument to the payload creator function
    const currentState = thunkAPI.getState() as RootState; // Access the current state using getState
    // Check if the user is not authenticated
    if (!currentState.auth.isAuthenticated) {
      const response = await getMeService();

      if (response.data) {
        return response.data.user;
      }

      throw new Error("Auth Error");
    }
    // User is already authenticated, return early with a default value
    return currentState.auth.user;
  }
);

// Async action to check user authentication status from API
export const login = createAsyncThunk<any, any>(
  "auth/login",
  async (payload: { phone: string; password: string }, thunkAPI) => {
    const response = await loginService(payload);

    if (response.data) {
      localStorage.setItem("Auth", response.data.authToken);
      return response.data.user;
    }
    throw new Error("Auth Error");
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state) {
      state.isAuthenticated = true;
      state.loading = false;
    },
    logout(state) {
      localStorage.removeItem("Auth");
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAuthStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkAuthStatus.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(checkAuthStatus.rejected, (state) => {
        state.user = undefined;
        state.isAuthenticated = false;
        state.loading = false;
      })
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(login.rejected, (state) => {
        state.user = undefined;
        state.isAuthenticated = false;
        state.loading = false;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
