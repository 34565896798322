import i18n from "i18next";

import { initReactI18next } from "react-i18next";

import { AllowedLanguages } from "i18n";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
export const getCurrentLanguage = (): AllowedLanguages => {
  return (
    (localStorage.getItem("app:locale") as AllowedLanguages) ||
    AllowedLanguages.en
  );
};
export const supportedLngs = {
  en: "English",
  ar: "Arabic (العربية)",
};
i18n
  .use(Backend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },

    lng: getCurrentLanguage(),

    fallbackLng: AllowedLanguages.en,

    supportedLngs: Object.keys(supportedLngs),

    debug: false,
  });

export default i18n;
