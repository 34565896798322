import { CircularProgress, Container, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const FullLoadingPage: React.FC = () => {
  const { t } = useTranslation("layout");
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <CircularProgress size={80} />
      <Typography variant="h6" sx={{ marginTop: 2 }}>
        {t("loading")}
      </Typography>
    </Container>
  );
};

export default FullLoadingPage;
