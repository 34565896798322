/* eslint-disable */
import { Breakpoint, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useMemo } from "react";

// Define the types for the queries
type QueryType = "up" | "down" | "between" | "only";

export function useResponsive(
  query: QueryType,
  start: Breakpoint,
  end?: Breakpoint
) {
  const theme = useTheme();

  const mediaUp = useMediaQuery(theme.breakpoints.up(start));
  const mediaDown = useMediaQuery(theme.breakpoints.down(start));
  const mediaOnly = useMediaQuery(theme.breakpoints.only(start));
  const mediaBetween = query ==='between' && end ? useMediaQuery(theme.breakpoints.between(start, end)) : false

  const match = useMemo(() => {
    switch (query) {
      case "up":
        return mediaUp;
      case "down":
        return mediaDown;
      case "between":
        return mediaBetween;
      default:
        return mediaOnly;
    }
  }, [query, mediaUp, mediaDown, mediaBetween, mediaOnly]);

  return match;
}
