import React, { Suspense } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";

import { ThunkDispatch } from "@reduxjs/toolkit";

import ProtectedComponent from "@components/permissions/ProtectedComponent";
import { PermissionsEnum } from "@interfaces/user";
import SingleRolePage from "@views/single-role";
import { useDispatch } from "react-redux";
import DashboardLayout from "../layouts/dashboard";
import { checkAuthStatus } from "../reducers/auth";
import { RootState } from "../reducers/store";
import FullLoadingPage from "../views/loading";
import ProtectedRouteWrapper from "./protected";

const RolesPage = React.lazy(() => import("@views/roles"));
const NotificationPage = React.lazy(() => import("@views/notifications"));

const UpdateMedicalQuestionPage = React.lazy(
  () => import("@views/update-medical-question")
);

const EditContentSettings = React.lazy(
  () => import("@views/edit-content-setting")
);

const TagsPage = React.lazy(() => import("@views/tags"));
const SingleTagPage = React.lazy(() => import("@views/single-tag"));

const NewMedicalQuestionPage = React.lazy(
  () => import("@views/new-medical-question")
);
const DoctorsPage = React.lazy(() => import("@views/doctors"));
const MedicalQuestionsPage = React.lazy(
  () => import("@views/medical-questions")
);
const SettingsPage = React.lazy(() => import("@views/settings"));
const ComplaintsPage = React.lazy(() => import("@views/complaints"));
const CategoryPage = React.lazy(() => import("@views/category"));
const ConsultingListPage = React.lazy(() => import("@views/consultings"));
const LoginPage = React.lazy(() => import("../views/login"));
const IndexPage = React.lazy(() => import("../views/overview"));
const SingleUserPage = React.lazy(() => import("../views/single-user/index"));
const UsersPage = React.lazy(() => import("../views/users"));
const SingleConsultingPage = React.lazy(
  () => import("../views/single-consulting")
);

const ConsultingPackagePage = React.lazy(
  () => import("../views/consultings-packages")
);

const HomeBannersPage = React.lazy(() => import("../views/home-banners"));
const ArticlesPage = React.lazy(() => import("../views/articles"));
const SingleCategoryPage = React.lazy(() => import("../views/single-category"));
const CreateArticlePage = React.lazy(() => import("../views/create-article"));
const EditArticlePage = React.lazy(() => import("../views/edit-article"));

export default function Router() {
  const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();

  React.useEffect(() => {
    if (dispatch) dispatch(checkAuthStatus());
  }, [dispatch]);

  const routes = useRoutes([
    {
      element: (
        <ProtectedRouteWrapper>
          <DashboardLayout>
            <Suspense fallback={<FullLoadingPage />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </ProtectedRouteWrapper>
      ) /*  */,
      children: [
        {
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <IndexPage />
            </Suspense>
          ),
          index: true,
        },

        {
          path: "articles/new",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_ARTICLES]}
              >
                <CreateArticlePage />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "articles/:id/edit",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_ARTICLES]}
              >
                <EditArticlePage />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "articles",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_ARTICLES]}
              >
                <ArticlesPage />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "home-banners",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_HOME_BANNERS]}
              >
                <HomeBannersPage />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "categories",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[
                  PermissionsEnum.CAN_MANAGE_HOME_VIDEO_CATEGORIES,
                ]}
              >
                <CategoryPage />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "categories/:id",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[
                  PermissionsEnum.CAN_MANAGE_HOME_VIDEO_CATEGORIES,
                ]}
              >
                <SingleCategoryPage />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "consultings",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_CONSULTING]}
              >
                <ConsultingListPage />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "consultings/:id",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_CONSULTING]}
              >
                <SingleConsultingPage />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "consulting-packages",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_PACKAGES]}
              >
                <ConsultingPackagePage />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "users",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_USERS]}
              >
                <UsersPage />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "users/:id/*",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_USERS]}
              >
                <SingleUserPage />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "complaints",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_COMPLAINTS]}
              >
                <ComplaintsPage />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "settings",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_SETTINGS]}
              >
                <SettingsPage />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "settings/editable-content/:name",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_SETTINGS]}
              >
                <EditContentSettings />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "doctors",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_USERS]}
              >
                <DoctorsPage />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "medical-questions",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[
                  PermissionsEnum.CAN_MANAGE_MEDICAL_QUESTIONS,
                ]}
              >
                <MedicalQuestionsPage />
              </ProtectedComponent>
            </Suspense>
          ),
        },

        {
          path: "medical-questions/new",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[
                  PermissionsEnum.CAN_MANAGE_MEDICAL_QUESTIONS,
                ]}
              >
                <NewMedicalQuestionPage />
              </ProtectedComponent>
            </Suspense>
          ),
        },

        {
          path: "medical-questions/:id",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[
                  PermissionsEnum.CAN_MANAGE_MEDICAL_QUESTIONS,
                ]}
              >
                <UpdateMedicalQuestionPage />
              </ProtectedComponent>
            </Suspense>
          ),
        },

        {
          path: "tags",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_TAGS]}
              >
                <TagsPage />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "tags/:id",
          element: (
            <Suspense fallback={<FullLoadingPage />}>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_TAGS]}
              >
                <SingleTagPage />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "notifications",
          element: (
            <Suspense>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_NOTIFICATIONS]}
              >
                <NotificationPage />
              </ProtectedComponent>
            </Suspense>
          ),
        },
        {
          path: "roles",
          element: (
            <Suspense>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_ROLES]}
              >
                <RolesPage />
              </ProtectedComponent>
            </Suspense>
          ),

          /* RolesPage */
        },
        {
          path: "roles/:id",
          element: (
            <Suspense>
              <ProtectedComponent
                requiredPermissions={[PermissionsEnum.CAN_MANAGE_ROLES]}
              >
                <SingleRolePage />
              </ProtectedComponent>
            </Suspense>
            /*  */
          ),
        },
      ],
    },
    {
      path: "login",
      element: (
        <Suspense fallback={<FullLoadingPage />}>
          <LoginPage />
        </Suspense>
      ),
    },

    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
