import Box from "@mui/material/Box";
import { forwardRef, memo } from "react";

import { useLanguage } from "@theme/language";
import { StyledRootScrollbar, StyledScrollbar } from "./styles";

const Scrollbar = forwardRef(({ children, sx, ...other }: any, ref) => {
  const { direction: appDirection } = useLanguage();
  // Detecting if the user agent is mobile
  const isMobile =
    typeof navigator !== "undefined" &&
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  if (isMobile) {
    // For mobile devices, use native overflow
    return (
      <Box ref={ref} sx={{ overflow: "auto", ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  // For non-mobile devices, use custom scrollbar
  return (
    <StyledRootScrollbar>
      <StyledScrollbar
        scrollableNodeProps={{ ref }}
        clickOnTrack={false}
        sx={sx}
        {...other}
        dir={appDirection}
      >
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  );
});

export default memo(Scrollbar);
