import { PermissionsEnum } from "@interfaces/user";
import store from "@reducers/store";

export const checkPermissions = (
  requiredPermissions: PermissionsEnum[],
  _userPermissions: { [key in PermissionsEnum]?: string } = store.getState()
    ?.auth?.user?.role?.permissions || {}
): boolean => {
  return requiredPermissions.every((permission) =>
    Boolean((_userPermissions as any)[permission])
  );
};
