import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import GenericAlert from "./components/alert";

import { LanguageProvider } from "@theme/language";
import store from "./reducers/store";
import Router from "./routes";
import ThemeProvider from "./theme";
function App() {
  return (
    <LanguageProvider>
      <ThemeProvider>
        <Provider store={store}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
          <GenericAlert />
        </Provider>
      </ThemeProvider>
    </LanguageProvider>
  );
}

export default App;
