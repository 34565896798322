import moment from "moment";
import "moment/locale/ar"; // Import additional locales as needed
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import i18n, { getCurrentLanguage } from "../i18n/config";

export enum AllowedLanguages {
  en = "en",
  ar = "ar",
}

interface LanguageContextProps {
  language: AllowedLanguages;
  toggleLanguage: () => void;
  direction: "ltr" | "rtl";
}

const LanguageContext = createContext<LanguageContextProps | undefined>(
  undefined
);

const useLanguageState = () => {
  const [language, setLanguage] = useState<AllowedLanguages>(
    getCurrentLanguage()
  );

  useEffect(() => {
    moment.locale(language);
    i18n.changeLanguage(language);
  }, [language]);

  const toggleLanguage = () => {
    const newLanguage =
      language === AllowedLanguages.en
        ? AllowedLanguages.ar
        : AllowedLanguages.en;

    setLanguage(newLanguage);

    localStorage.setItem("app:locale", newLanguage);

    window.location.reload(); // Or re-render the application if necessary
  };

  return { language, toggleLanguage };
};

const useDirection = (language: AllowedLanguages): "ltr" | "rtl" => {
  return language === AllowedLanguages.ar ? "rtl" : "ltr";
};

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({
  children,
}) => {
  const { language, toggleLanguage } = useLanguageState();
  const direction = useDirection(language);

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage, direction }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): LanguageContextProps => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
