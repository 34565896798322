import React from "react";
import { Navigate } from "react-router-dom";

import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/store";
import FullLoadingPage from "../views/loading";

interface ProtectedRouteWrapperProps {
  children: React.ReactNode;
}

function ProtectedRouteWrapper({
  children,
}: ProtectedRouteWrapperProps): ReactElement {
  const { isAuthenticated, loading } = useSelector((s: RootState) => s.auth);

  if (loading) return <FullLoadingPage />;

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
}

export default ProtectedRouteWrapper;
