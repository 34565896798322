import { IUser } from "../../interfaces/user";
import apiCall, { ApiResponse } from "../http";

export const getMeService = async (): Promise<ApiResponse<{ user: IUser }>> => {
  return await apiCall("GET", "auth/me");
};

export const loginService = async (payload: {
  phone: string;
  password: string;
}): Promise<ApiResponse<{ authToken: string; user: IUser }>> => {
  return await apiCall<{
    authToken: string;
    user: IUser;
  }>("POST", "auth/login", payload);
};
